.show-modal{
  /* background-color: #cccccc70; */
  height: 100vh;
  width: 100vw;
}
.menu-widget {
  position: relative;
  display: inline-block;
}
.dropdown-content {
  display: none;
  position: absolute;
  right: 0;
  text-align: left;
  background-color: #fff;
  width: 220px;
  overflow: auto;
  box-shadow: 0px 8px 16px 0px #00000033;
  z-index: 100;
  border: 1px solid #9e9e9e;
}
.nav-link{
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  border-bottom: 1px solid #f0f0f0;
  font-size: 18px;
  font-weight: 400;
}
.nav-link:hover {
  background-color: #ececec; 
  text-decoration: none; 
  color: black;
  cursor: pointer;
}
.show-dropdown{ 
  display:block; 
}
.brgr-wrapper {
  display: inline-block;
  cursor: pointer;
  padding: 0;
  margin: 0;
}

@media only screen and (max-width: 600px){
  .nav-link {
    padding: 1em 1.6em !important;
  }
}