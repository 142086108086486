:root{
  --spinner-width: 7em;
  --spinner-width-small: 2em;
  --spinner-color1: #008744;
  --spinner-color2: #0057e7;
  --spinner-color3: #d62d20;
  --spinner-color4: #ffa700;
  --spinner-empty-color: #eee;
}


/* TEMP */
.temp-offset-for-input{
  margin-top: 15px !important;
  margin-bottom: -20px !important;
}
.temp-checkbox{
  margin-right: 10px;
  height: 20px;
  width: 20px;
  background-color: white;
}
.temp-short-input{
  max-width: 80px !important;
  height: 40px !important;
  margin: 5px 13px 0px 13px !important;
}
.with-shadow{
  box-shadow: 0 8px 20px 0 #d3d3d3;
}
.temp-clock-out-button{
  display: inline-block !important;
  height: '20px';
  width: '50px';
  border-radius: 0px !important;
  background-color: #5d5afa !important;
  margin-left: 20px;
}
.italic-text{
  padding: 0;
  margin: 0;
  font-style: italic;
}
.temp-data-label{
  font-size: 18px;
  padding: 0;
  margin: 0;
}
.temp-log-subtext{
  font-size: 14px;
  font-style: italic;
  padding: 0;
  margin: 0;
}
.temp-subsection{
  font-size: 20px;
  font-weight: bold;
  padding: 0px 0px 10px 0px;
  margin: 0;
}
.temp-short-button{
  margin: 0 !important;
  padding: 10px !important;
  max-height: 3em !important;
  max-width: 300px !important;
}


/* BUTTONS */
.tile-button{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 150px;
  width: 150px;
  border: 3px solid #21c1c7;
  background-color: #ffffff;
  color: black;
  box-shadow: 0 8px 20px 0 #d3d3d3;
  margin: 10px;
  padding: 20px;
}
.tile-button:hover, .tile-buttonr:focus, .tile-button:active{
  background-color: #21c1c7;
  color: #ffffff;
  border: 3px solid #21c1c7;
}
.close-detail-button{
  background-color: red;
  border: 1px solid red;
  color: #ffffff;
  margin-top: 22px;
  padding: 3px 15px;
}
.no-offset{
  margin: 0;
}
.add-button{
  background-color: #5d5afa;
  border: 1px solid #5d5afa;
  color: #ffffff;
  margin-top: 22px;
  padding: 3px 15px;
}
.sort-button{
  background-color: #4848ff;
  border: 1px solid #4848ff;
  color: #ffffff;
  margin-top: 22px;
  width: 70px;
}
.delete-button{
  background-color: #ff0000 !important;
}
.reasonably-sized-button{
  max-width: 350px !important;
}
.report-button{
  margin: 0 5px !important;
  /* margin-left: 5 !important; */
  padding: 10px !important;
  max-height: 3em !important;
  width: 200px !important;
}



/* TEXT */
p {
  font-family: 'Helvetica Neue', sans-serif;
}
.site-header{
  color: #ffffff;
  cursor: pointer;
  font-family: Alegreya Sans, system-ui;
  font-weight: 300;
  font-size: 40px;
  padding: 0;
  margin: 0;
}
.login-header{
  color: #ffbd67;
  font-size: 1px;
}
.page-header{
  font-family: Alef, system-ui;
  font-weight: 500;
  font-size: 30px;
  text-align: left;
  margin: 0px 0px 0px 0px;
  padding: 0px;
}
.err-text{
  color: red;
  font-style: italic;
  margin: 0;
  padding: 0;
  text-align: left;
}
.dialog-header{
  font-size: 22px;
  font-weight: bold;
  margin: 0px;
  padding: 0px;
}
.tile-button-content{
  font-weight: 500;
  font-size: 20px;
  padding: 0;
  margin: 0;
  text-align: center;
}
.hours-worked-period{
  display: inline-block;
  padding: 0;
  margin: 0;
  font-style: italic;
  text-align: right;
  width: 150px;
}
.hours-worked-value{
  display: inline-block;
  padding: 0;
  padding-left: 5px;
  margin: 0;
  font-style: italic;
  text-align: left;
}




/* COMPONENTS */
.sticky-header{
  position: sticky;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 5px 30px;
  background-color: #ffbd67;
  color: black;
  z-index: 1;
}
.base-textarea{
  /* background-color: green !important; */
  min-height: 6.6em !important;
}
.invoice-description-input{
  min-height: 16em !important;
}
.timelog-notes-input{
  min-height: 16em !important;
}
.reimbursable-description-input{
  min-height: 9.6em !important;
}
.tickmark-container{
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
.tickmark{
  width: 2px;
  height: 6px;
  background-color: #525252;
}
.reasonably-sized-menu{
  max-height: 250px !important;
  overflow: auto !important;
}
.pie {
  /* Basic layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 12em;
  height: 12em;
  border-radius: 50%;

  /* A little styling */
  border: .15em solid #fff;
  box-shadow: 0 .075em .2em .05em rgba(0,0,0,.25);

  /* fixes a minor clipping issue in Chrome */
  background-origin: border-box; 
  background-image: conic-gradient(
    var(--segment-color) 0 calc(3.6deg * var(--segment, 100)),
    #eeeeee 0
  );
}
.pie-text{
  text-align: center;
  padding: 0;
  margin: 0;
}


/* CONTAINERS */
.main{
  background-color: #f0f0f0;
  overflow: auto;
}
.content-view{
  min-height: 85vh;
}
.centered-container{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
}
.content-card{
  background-color: #ffffff;
  box-shadow: 0 8px 20px 0 #d3d3d3;
  padding: 2em;

  border: 1px solid #f8f8f8;
}
.singular-card{
  margin-top: 20px;
}
.centered-row{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}
.item-container{
  background-color: #ffffff;
  color: #000000;
  border: 2px solid #ffbd67;
  height: auto;
  /* width: auto; */
  min-width: 50%;
  width: 300px;
  /* max-width: 500px; */
  padding: 10px;
  margin-top: 5px;
}
.timer-flex{
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
.item-container:hover, .item-container:focus, .item-container:active{
  background-color: #ffb451;
  /* color: #ffffff; */
  border: 2px solid #ffb451;
}
.upload-item{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid grey;
  /* margin-bottom: 3px; */
  padding: .4em;
}
.upload-item:hover{
  background-color: #E6FAFF;
}
.grid-row{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  border-left: 1px solid #000000;
}
.grid-cell{
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  border-right: 1px solid #000000;
  border-top: 0.5px solid #000000;
  /* border-bottom: 0.5px solid #000000; */
  padding: 5px;
  height: 2.3em;
  overflow: hidden;
}
.ghost-cell{
  border: 1px solid #ffffff;
}
.grid-header{
  padding: 0;
  margin: 0;
  font-weight: bold;
}
.grid-text{
  padding: 0;
  margin: 0;
}
.pie-box{
  margin: 0.7em;
}
.item-hover{
  background-color: #ffffff;
  transition: background-color 0.3s;
}
.item-hover:hover{
  background-color: #daf6ff;
}
.timer-highlight{
  background-color: #dcfadc;
}
.report-options-wrapper{
  display: flex;
  /* width: 70%; */
}



@media only screen and (max-width: 600px){
  .content-view {
    min-width: 80vw;
    /* background-color: rgb(255, 255, 197); */
  }
  .singular-card{
    margin: 0px;
    box-shadow: none;
    border: none;
  }
  .tile-button{
    height: 120px;
    width: 120px;
    padding: 10px;
  }
}


/* CUSTOM ELEMENTS */
/* initially label is laid over on input */
label {
  display: inline-block;
  font-size: 18px;
  position: relative;
  left: 12px;
  top: 47px;
  transition: all 150ms ease-in;
  color: #676767;
  pointer-events: none;
}
/* when field is active, label moves up */
label.field-active {
  transform: translateY(-26px);
  font-size: 18px;
  color: #3f3f3f;
  text-shadow: 2px 0 0 #fff, -1px 0 0 #fff, 2px 0 0 #fff, -2px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff, 0 2px 0 #fff, 0 -2px 0 #fff;
}
.input-err{
  border: 2px solid red !important;
}
.datepicker{
  background-color: #f8f8f8 !important;
  box-shadow: none !important; 
  border: none !important; 
  border-bottom: 1px solid darkblue !important;
  border-radius: 0px !important;
  outline: none !important;
  height: 50px !important;
  font-size: 18px !important;
}
.upload-input{
  width: 150px !important;
  margin-top: 15px;
  height: 40px !important;
}
.rm-f-button{
  height: 35px;
  background-color: red !important;
}
.incrementor-btn{
  background-color: var(--primary);
  color: #ffffff;
  border-radius: 10px;
  border: none;
  font-size: 14px;
  padding: 5px 10px;
}



/* TEST CHECKBOX STYLING */
.checkbox input[type="checkbox"] {
  opacity: 0;
}

.checkbox label {
  position: relative;
  display: inline-block;
  
  /*16px width of fake checkbox + 6px distance between fake checkbox and text*/
  padding-left: 22px;
}

.checkbox label::before,
.checkbox label::after {
  position: absolute;
  content: "";
  
  /*Needed for the line-height to take effect*/
  display: inline-block;
}

/*Outer box of the fake checkbox*/
.checkbox label::before{
  height: 16px;
  width: 16px;
  
  border: 1px solid;
  left: 0px;
  
  /*(24px line-height - 16px height of fake checkbox) / 2 - 1px for the border
   *to vertically center it.
   */
  top: 3px;
}

/*Checkmark of the fake checkbox*/
.checkbox label::after {
  height: 5px;
  width: 9px;
  border-left: 2px solid;
  border-bottom: 2px solid;
  
  transform: rotate(-45deg);
  
  left: 4px;
  top: 7px;
}

/*Hide the checkmark by default*/
.checkbox input[type="checkbox"] + label::after {
  content: none;
}

/*Unhide on the checked state*/
.checkbox input[type="checkbox"]:checked + label::after {
  content: "";
}



/* SPINNER STYLE */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
.loader {
  display: inline-block;
  width: var(--spinner-width);
  height: var(--spinner-width);
}
.small-loader{
  display: inline-block;
  width: var(--spinner-width-small);
  height: var(--spinner-width-small);
}
.circular {
  animation: rotate 2s linear infinite;
}
.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite, color 6s ease-in-out infinite;
  stroke-linecap: round;
}
@keyframes rotate {
  100% {
      transform: rotate(360deg);
  }
}
@keyframes dash {
  0% {
      stroke-dasharray: 1, 200;
      stroke-dashoffset: 0;
  }

  50% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -35px;
  }

  100% {
      stroke-dasharray: 89, 200;
      stroke-dashoffset: -124px;
  }
}
@keyframes color {

  100%,
  0% {
      stroke: var(--spinner-color3);
  }

  40% {
      stroke: var(--spinner-color2);
  }

  66% {
      stroke: var(--spinner-color1);
  }

  80%,
  90% {
      stroke: var(--spinner-color4);
  }
}